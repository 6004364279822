<template>
  <section class="content">
    <breadcrumb></breadcrumb>
    <div class="account-element flex">
      <div class="left-content">
        <a-tree
          :tree-data="treeList"
          :selectedKeys.sync="selectedKeys"
          :expandedKeys="expandedKeys"
          @expand="onExpand"
          @select="onSelect"
        />
      </div>
      <div class="table-content">
        <div class="table-header">
          <Pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          />
        </div>
        <div class="table-body">
          <a-table
            size="small"
            :columns="columns"
            :data-source="tableList"
            :loading="loading"
            :pagination="false"
            rowKey="code"
          >
            <template slot="code" slot-scope="text, record">
              <span class="text-link" @click="viewDetail(record)">{{ text }}</span>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import { buildTree } from '@/common'
import Pagination from '@/component/pagination'
export default {
  name: 'accountList',
  components: { Pagination },
  mounted() {
    this.getTreeList()
    this.getTableList()
  },
  data() {
    return {
      treeLoading: false,
      loading: false,
      treeList: [],
      tableList: [],
      rootCode: undefined,
      selectedKeys: ['all'],
      expandedKeys: [],
      visible: false,
      form: {
        code: '',
        name: '',
        balance_direction: '',
      },
      rules: {
        code: [{ required: true, message: this.$t('请输入编码'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('请输入名称'), trigger: 'blur' }],
        balance_direction: [
          { required: true, message: this.$t('请选择余额方向'), trigger: 'change' },
        ],
      },
      pageNo: 1,
      pageSize: 20,
      total: 1,
    }
  },
  computed: {
    ...mapState(['balance_direction_mapping']),
    columns() {
      return [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: this.$t('编码'),
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' },
        },
        {
          title: this.$t('名称'),
          dataIndex: 'name',
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimension_name',
        },
        {
          title: this.$t('会计要素表'),
          dataIndex: 'root_name',
        },
        {
          title: this.$t('余额方向'),
          dataIndex: 'balance_direction',
          customRender: (text) => this.balance_direction_mapping[text],
        },
        {
          title: this.$t('上级会计要素'),
          dataIndex: 'parent_name',
        },
        {
          title: this.$t('系统预置'),
          dataIndex: 'is_preset',
          customRender: (text) => {
            return text ? this.$t('是') : this.$t('否')
          },
        },
        {
          title: this.$t('备注'),
          dataIndex: 'remark',
        },
      ]
    },
  },
  methods: {
    getTreeList() {
      this.treeLoading = true
      http({
        url: api.getAccountElementist,
        type: 'post',
        success: (res) => {
          this.treeLoading = false
          const tree = buildTree(res.result.list)
          this.treeList = [
            {
              title: this.$t('全部'),
              key: 'all',
              children: tree,
            },
          ]
          this.expandedKeys = ['all']
        },
      })
    },
    getTableList(params = {}) {
      this.loading = true
      http({
        url: api.getAccountList,
        type: 'post',
        data: { page_no: this.pageNo, page_size: this.pageSize, ...params },
        success: (res) => {
          this.loading = false
          this.tableList = res.result.list
          this.total = res.result.total
        },
      })
    },
    onSelect(selectedKeys) {
      this.pageNo = 1
      const data = {}
      if (selectedKeys.length) {
        if (selectedKeys[0] !== 'all') {
          let arr = selectedKeys[0].split('-')
          if (arr.length === 1) {
            let selectItem = this.treeList[0].children.find((elem) => elem.code === arr[0])
            data.elementCodeList =
              selectItem.childrenCodeList?.length > 0 ? selectItem.childrenCodeList : arr
          } else {
            data.elementCodeList = [arr[1]]
          }
        }
        this.getTableList(data)
      }
    },
    viewDetail(record) {
      this.$router.push({
        name: 'accountDetail',
        query: { id: record.id },
      })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.getTableList()
    },
  },
}
</script>

<style scoped lang="scss">
.left-content {
  min-width: 150px;
  width: 250px;
  border-right: 1px solid #e8e8e8;
  height: calc(100vh - 180px);
}
.table-content {
  flex: 1;
}
</style>
